.not-found {
    text-align: center;
    padding: 100px 0px 50px;
    font-family: 'Raleway', sans-serif;
    color: #333;
}
.not-found h2 {
    font-size: 36px;
}
.not-found h3 {
    font-size: 22px;
    font-weight: normal;
}
.not-found h2 span {
    background-color: #eee;
    color: #03A9F4;
    width: 200px;
    height: 170px;
    display: inline-grid;
    align-items: center;
    font-size: 100px;
    border-radius: 20px;
    font-style: italic;
    margin-bottom: 15px;
}
.not-found p {
    font-size: 17px;
    line-height: 32px;
    color: #9E9E9E;
}
.not-found .go-on {
    padding: 20px 0px 20px;
}
.not-found .go-on a {
    padding: 7px 15px;
    background-color: #2196F3;
    border-radius: 4px;
    font-size: 20px;
    margin: 10px 10px;
    display: inline-block;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
}
.not-found .go-on a:hover{
  opacity: 0.8;
}
.not-found form input {
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 10px;
    box-sizing: border-box;
}
.not-found form .submit-btn {
    height: 40px;
    border: none;
    margin-left: 10px;
    color: #fff;
    background-color: #03a9f4;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px 15px;
}
.not-found form .submit-btn:hover{
  opacity: 0.8;
}
.not-found hr {
    border: 1px solid #eeeeee;
    margin: 30px 0px;
}