@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap');
* {
  margin: 0;
  padding: 0;
}

.collapsess {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
}

.headerbg {
  background-color: #ffffff;
}

.remove {
  transition: none !important;
  box-shadow: none !important;
}

.card1 {
  background-color: #118f240a!important;
}

.paddingg {
  padding: 0rem!important;
}

.bgheader {
  background-color: #e9ebed;
  ;
}
.ext3{
  font-family: 'STIX Two Text', serif;
}
a.ext3 {
  color: #00334E!important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a.ext3:hover {
  color: #0F52BA!important;
}